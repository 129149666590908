import {
  IAccountLink,
  ICancelSubscriptionRequest,
  ICreateSubscriptionRequest,
  IProviderSubscription,
  IRetryInvoiceRequest,
  ISubscriptionCouponResponse,
  ISubscriptionResponse,
  ISubscriptionSetupResponse,
  IUpdateSubscriptionRequest
} from 'src/types/subscription'
import { instanceApi } from 'src/utils/axios'
import { Service } from './core/service'

export class SubscriptionService extends Service {
  protected apiPath = 'api/app/subscription'

  async setup() {
    return instanceApi.post<ISubscriptionSetupResponse>(`${this.apiPath}/setup`)
  }

  getProviderSubscription() {
    return instanceApi.get<IProviderSubscription>(
      `${this.apiPath}/provider-subscription`
    )
  }

  createSubscription(request: ICreateSubscriptionRequest) {
    return instanceApi.post<ISubscriptionResponse>(
      `${this.apiPath}/create-subscription`,
      request
    )
  }

  updateSubscription(request: IUpdateSubscriptionRequest) {
    return instanceApi.put<ISubscriptionResponse>(
      `${this.apiPath}/subscription`,
      request
    )
  }

  retryInvoice(request: IRetryInvoiceRequest) {
    return instanceApi.post(`${this.apiPath}/retry-invoice`, request)
  }

  cancelSubscription(request: ICancelSubscriptionRequest) {
    return instanceApi.post<ISubscriptionResponse>(
      `${this.apiPath}/cancel-subscription`,
      request
    )
  }

  connectAccount() {
    return instanceApi.post<void>(`${this.apiPath}/connect-account`)
  }

  connectAccountLink() {
    return instanceApi.post<IAccountLink>(
      `${this.apiPath}/connect-account-link`
    )
  }

  createPaymentIntent(providerId: string) {
    return instanceApi.post<string>(
      `${this.apiPath}/payment-intent/${providerId}`
    )
  }

  validateCoupon(promotionCode: string) {
    return instanceApi.get<ISubscriptionCouponResponse>(
      `${this.apiPath}/coupon?promotionCode=${promotionCode}`
    )
  }
}
