import { IPagination } from 'src/types/core';
import { IAudioFile, ISearchAudioFileInput, ISpeechVoiceData } from 'src/types/tours';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class AudioFileService extends CRUDService<IAudioFile> {
  protected apiPath = 'api/app/audio-file';

  search(request: ISearchAudioFileInput = {}) {
    return instanceApi.get<IPagination<IAudioFile>>(`${this.apiPath}/search`, {
      params: request
    });
  }

  textToSpeech(text: string, speechVoiceName?: string) {
    return instanceApi.post<string>(`${this.apiPath}/text-to-speech`, null, { params: { text, speechVoiceName } });
  }

  getSpeechVoices() {
    return instanceApi.get<ISpeechVoiceData[]>(`${this.apiPath}/speech-voices`);
  }
}