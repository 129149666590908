/* eslint-disable no-unused-vars */
import {
  ICalendarEventParent,
  ICreateCalendarEventParentsInput,
  IUpdateCalendarEventParentsInput
} from 'src/types/calendar'
import {
  ICancelBookingProviderInput,
  IUpdateBookingInput
} from 'src/types/calendarSchedule'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class CalendarEventParentService extends CRUDService<ICalendarEventParent> {
  protected apiPath = '/api/app/calendar-event-parent'

  createCalendarEventParents(request: ICreateCalendarEventParentsInput) {
    return instanceApi.post(`${this.apiPath}/calendar-event-parents`, request)
  }

  updateCalendarEventParents(request: IUpdateCalendarEventParentsInput) {
    return instanceApi.put(`${this.apiPath}/calendar-event-parents`, request)
  }

  getCalendarEventsByParent() {
    return instanceApi.get(`${this.apiPath}/by-parent`)
  }

  cancelScheduledTour(request: ICancelBookingProviderInput) {
    return instanceApi.post(`${this.apiPath}/cancel-booking`, request)
  }

  updateScheduledTourByParent(id: string, request: IUpdateBookingInput) {
    return instanceApi.put(`${this.apiPath}/${id}/by-parent`, request)
  }

  updateScheduledTourStatus(id: string, status: string) {
    return instanceApi.put(`${this.apiPath}/${id}/status?status=${status}`)
  }
}
