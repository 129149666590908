import { ISlide } from 'src/types/slide';
import { ISlideshowSlide } from 'src/types/slideshow';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class SlideshowSlideService extends CRUDService<ISlideshowSlide> {
  protected apiPath = '/api/app/slideshow-slide';

  async sync(newFiles: ISlide[], input: any = {}) {
    const request = {
      ...input,
      slides: newFiles.map((file, index) => ({
        slideId: file.id,
        fileOrder: index
      }))
    };
    return instanceApi.post<ISlideshowSlide[]>(
      `${this.apiPath}/multiple-files`,
      request
    );
  }
}
