import { IEnrollmentProgramSchedule } from 'src';
import { IBaseEntity } from './core'

export interface IEnrollmentPrice extends IBaseEntity {
  name?: string;
  amount?: number;
  providerId?: string;
  enrollmentProgramScheduleId?: string;
  enrollmentProgramSchedule?: IEnrollmentProgramSchedule;
  terms?: EnrollmentPriceTermEnum;
}


export enum EnrollmentPriceTermEnum {
  DAILY = 0,
  WEEKLY = 1,
  MONTHLY = 2,
  TOTAL = 3,
}
