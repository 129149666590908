import { IBaseEntity } from './core'

export interface ISubscription extends IBaseEntity {
  tenantId?: string
  editionId?: string
  stripeCustomerId?: string
  stripeSubscriptionId?: string
  edition?: ISaasEditions
  periodStartTime?: string
  periodEndTime?: string
  price?: number
  status?: SubscriptionStatusEnum
  trialEnd?: string
}

export interface IProviderSubscription extends IBaseEntity {
  tenantId?: string
  subscriptionId?: string
  subscription?: ISubscription
  providerId?: string
  virtualStoryTotalCount?: number
  virtualStoryPublished?: number
  slideShowTotalCount?: number
  slideShowPublished?: number
}

export interface ISaasEditions extends IBaseEntity {
  displayName?: string
}

export interface ISubscriptionSetupResponse {
  publishableKey: string
  enhancedPrice: ISubscriptionSetupResponsePrice
  basicPrice: ISubscriptionSetupResponsePrice
  premiumPrice: ISubscriptionSetupResponsePrice
}

export interface IRetryInvoiceRequest {
  customer: string
  paymentMethod: string
  invoice: string
}

export interface ICreateSubscriptionRequest {
  paymentMethod?: string
  price: string
  billingDetails: IBillingDetails
  coupon?: string
}

export interface IUpdateSubscriptionRequest {
  subscription: string
  newPrice: string
}

export interface ICancelSubscriptionRequest {
  subscription: string
}

export interface ISubscriptionResponse {
  subscription: any
  error: string
}

export interface ISubscriptionCouponResponse {
  coupon: any
  active: boolean
  code: string
}

export interface ISubscriptionSetupResponsePrice {
  priceId: string
  price: string
}

export interface IBillingDetails {
  name: string
  email: string
  phone: string
}

export interface ISubscriptionMatrix {
  edition: SubscriptionEditionsEnum
  virtualStory: ISubscriptionMatrixVirtualStory
  slideShow: ISubscriptionMatrixSlideShow
}

export interface ISubscriptionMatrixVirtualStory {
  totalCount: number
  publishedCount: number
  duration: number //sec
}

export interface ISubscriptionMatrixSlideShow {
  totalCount: number
  publishedCount: number
  photoCount: number
}

export interface IAccountLink {
  url: string
}

export enum SubscriptionEditionsEnum {
  STANDARD = 'Standard',
  ESSENTIAL = 'Essential',
  PREMIUM = 'Premium'
}

export enum SubscriptionStatusEnum {
  PENDING = 'Pending',
  DRAFT = 'Draft',
  OPEN = 'Open',
  UNCOLLECTIBLE = 'Uncollectible',
  PAID = 'Paid',
  VOID = 'Void',
  FAILED = 'Failed'
}
