/* eslint-disable no-unused-vars */
import { CRUDService } from './core/crudService'
import { instanceApi } from 'src/utils'
import { IMicrositeActivity } from 'src/types/micrositeActivity'

export class MicrositeActivityService extends CRUDService<IMicrositeActivity> {
  protected apiPath = '/api/app/micro-site-activity'
  protected fillable = []

  get() {
    return instanceApi.get<IMicrositeActivity>(`${this.apiPath}`)
  }
}
