import { IEnrollmentAge, IEnrollmentClassOption, IEnrollmentProgramSchedule, IEnrollmentSession } from 'src'
import { IBaseEntity } from './core'
import { IProgram } from './program'

export interface IEnrollmentClass extends IBaseEntity {
  title?: string;
  description?: string;
  providerId?: string;
  enrollmentProgram?: IProgram;
  enrollmentProgramId?: string;
  enrollmentAgeIds?: string[];
  enrollmentAges?: IEnrollmentAge[];
  enrollmentSessionId?: string;
  enrollmentSession?: IEnrollmentSession;
  enrollmentProgramScheduleId?: string;
  enrollmentProgramSchedule?: IEnrollmentProgramSchedule;
  enrollmentClassesOptionsInput?: IEnrollmentClassOption[];
  enrollmentClassesOptions?: IEnrollmentClassOption[];
  micrositeImageUrl?: any;
  enrollmentClassAges?: IEnrollmentClassAge[];

  enrollmentAgeId?: string;
  capacity?: number
  age?: number
  price?: number
  priceType?: string
  pricing?: string
  location?: string
  startHour?: string
  endHour?: string
  daysOfOperation?: string | string[]
  orderNumber?: number
  openings?: number
  enrolled?: number
  isEditable?: boolean
}

export interface IEnrollmentClassAge extends IBaseEntity {
  tenantId?: string;
  providerId?: string;
  enrollmentClassId?: string;
  enrollmentAgeId?: string;
  enrollmentAge?: IEnrollmentAge;
}


export enum DaysNameEnum {
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
  SUNDAY = 'Sunday'
}

export enum EnrollmentClassBillingEnum {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  SINGLE_PAYMENT = 'single_payment',
  OTHER = 'other'
}
