import { IEnrollmentProgramSchedule } from 'src/types/program'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class EnrollmentProgramScheduleService extends CRUDService<IEnrollmentProgramSchedule> {
  protected apiPath = '/api/app/enrollment-program-schedule'

  getByChildAge(request: any[]) {
    return instanceApi.get<IEnrollmentProgramSchedule>(
      `${this.apiPath}/by-child-age`,
      { params: request }
    )
  }
}
