/* eslint-disable no-unused-vars */
import { map, sumBy } from 'lodash'
import {
  ICreateDealWithDetailsInput,
  IDeal,
  IDealRegisterChild
} from 'src/types/deal'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'
import { FamilyService } from './familyService'

const familyService = FamilyService.getInstance<FamilyService>()

export class DealService extends CRUDService<IDeal> {
  protected apiPath = 'api/app/deal'

  protected fillable = [
    'title',
    'pipelineStageId',
    'providerId',
    'dealOrder',
    'parentsId',
    'childsId',
    'familyId',
    'tourDate',
    'note',
    'source',
    'type',
    'status',
    'tourStatus',
    'registerLead',
    'enrollmentProgramId',
    'enrollmentProgramScheduleId'
  ]

  createWithDetails(request: ICreateDealWithDetailsInput = {}) {
    return instanceApi
      .post<IDeal>(`${this.apiPath}/validate-and-create`, request)
      .then((resp) => {
        resp.data = this.mapItem(resp.data)
        return resp
      })
  }

  createRegisterChild(request: IDealRegisterChild = {}) {
    return instanceApi.post<IDealRegisterChild>(
      `${this.apiPath}/early-register-child`,
      request
    )
  }

  getBySchedule(scheduleId) {
    let query = '?MaxResultCount=1000'

    if (scheduleId !== 'unassigned') {
      query = `?ScheduleId=${scheduleId}&MaxResultCount=1000`
    }
    return instanceApi
      .get(`${this.apiPath}/by-schedule${query}`)
      .then((resp) => {
        resp.data.items = resp.data.items.map((file) => this.mapItem(file))
        return resp
      })
  }

  getByProgramId(programId, request: any = {}) {
    return instanceApi
      .get(`${this.apiPath}/by-program/${programId}`, request)
      .then((resp) => {
        resp.data.items = resp.data.items.map((file) => this.mapItem(file))
        return resp
      })
  }

  setProviderInitialView() {
    return instanceApi.post<any>(`${this.apiPath}/set-provider-initial-view`)
  }

  updateDealStatus(dealId: string, request: any = {}) {
    return instanceApi.put<any>(
      `${this.apiPath}/${dealId}/base-status`,
      request
    )
  }

  getRegistrationList() {
    return instanceApi.get<any>(`${this.apiPath}/registrations-list`)
  }

  mapItem(row: IDeal) {
    if (row.parents) {
      row.parentsId = row.parents.length > 0 ? map(row.parents, 'parentId') : []
    }
    if (row.family) {
      row.family = familyService.mapItem(row.family)
    }
    if (row.childs) {
      row.childsId = row.childs.length > 0 ? map(row.childs, 'childId') : []
      row.totalPrice =
        sumBy(row.childs, (child) =>
          sumBy(child.enrollmentClasses, (item) => item?.enrollmentClass?.price)
        ) || 0
    }

    return row
  }
}
