/* eslint-disable no-unused-vars */
import moment from 'moment'
import { IPagination } from 'src/types/core'
import {
  CalendarEventFrequencyType,
  ICalendarEvent,
  ICalendarRange,
  IFullCalendarEvent,
  IGetEventBetweenDatesInput
} from 'src/types/calendar'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'
import {
  IEventInput,
  IEventsScheduleInput,
  ICancelBookingProviderInput,
  IConfirmBookingParentInput,
  ICreateBookingInput,
  IUpdateProviderBookingInput
} from 'src/types/calendarSchedule'

export class CalendarEventService extends CRUDService<ICalendarEvent> {
  protected apiPath = '/api/app/calendar-event'

  protected fillable = [
    'providerId',
    'provider',
    'title',
    'description',
    'allDay',
    'frequency',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'meetingLocation',
    'zoomMeetingId',
    'nullable',
    'parents',
    'childs',
    'duration',
    'daysInTheFuture',
    'meetingType',
    'bufferBeforeMeeting',
    'bufferAfterMeeting',
    'mondayHours',
    'tuesdayHours',
    'wednesdayHours',
    'thursdayHours',
    'fridayHours',
    'saturdayHours',
    'sundayHours',
    'overlapDates'
  ]

  getEventByDate(request: IGetEventBetweenDatesInput) {
    return instanceApi.get<IPagination<ICalendarEvent>>(
      `${this.apiPath}/between-dates`,
      { params: request }
    )
  }

  createBookingByProvider(request: ICreateBookingInput) {
    return instanceApi.post(`${this.apiPath}/provider-booking`, request)
  }

  updateBookingByProvider(request: IUpdateProviderBookingInput) {
    return instanceApi.put(`${this.apiPath}/provider-booking`, request)
  }

  createEventsSchedule(request: IEventsScheduleInput) {
    return instanceApi.post(`${this.apiPath}/with-schedule`, request)
  }

  updateEventsSchedule(calendarEventId: string, request: IEventsScheduleInput) {
    return instanceApi.put<string>(
      `${this.apiPath}/${calendarEventId}/with-schedule`,
      request
    )
  }

  getEventByType(meetingType: string) {
    return instanceApi.get<ICalendarEvent>(
      `${this.apiPath}/meeting-type?meetingType=${meetingType}`
    )
  }

  updateEventsByDate(calendarEventId: string, request: IEventInput[]) {
    return instanceApi.post(
      `${this.apiPath}/overlap-dates/${calendarEventId}`,
      request
    )
  }

  deleteEventsByDate(calendarEventId: string) {
    return instanceApi.delete(
      `${this.apiPath}/overlap-dates/${calendarEventId}`
    )
  }

  cancelBookingByProvider(request: ICancelBookingProviderInput) {
    return instanceApi.post(`${this.apiPath}/cancel-booking`, request)
  }

  confirmBookingByParent(request: IConfirmBookingParentInput) {
    return instanceApi.post(`${this.apiPath}/booking`, request)
  }

  getCalendarEventsByParent() {
    return instanceApi.get(`${this.apiPath}/by-parent`)
  }

  mapRecurringEvent(
    events: ICalendarEvent[],
    range?: ICalendarRange
  ): IFullCalendarEvent[] {
    const mappedEvents: IFullCalendarEvent[] = []
    for (const event of events) {
      let start = moment(
        moment(event.startDate).format('YYYY-MM-DD') + ' ' + event.startTime
      )

      if (event.frequency !== CalendarEventFrequencyType.NOT_REPEAT) {
        let end = moment(
          moment(event.endDate).format('YYYY-MM-DD') + ' ' + event.endTime
        )

        const startHours = start.get('hour')
        const startMinutes = start.get('minute')
        const endHours = end.get('hour')
        const endMinutes = end.get('minute')

        if (range) {
          if (moment(range.start).isAfter(event.startDate)) {
            start = moment(range.start)
          }
          if (moment(range.end).isBefore(end)) {
            end = moment(range.end)
          }
        }

        do {
          const newEvent: any = {
            id: event.id,
            allDay: event.allDay,
            groupId: event.id,
            start: start
              .clone()
              .set('hour', startHours)
              .set('minute', startMinutes)
              .toDate(),
            end: start
              .clone()
              .set('hour', endHours)
              .set('minute', endMinutes)
              .toDate(),
            title: event.title,
            description: event.description,
            data: event
          }
          mappedEvents.push(newEvent)
          switch (event.frequency) {
            case CalendarEventFrequencyType.DAILY:
              start.add(1, 'day')
              break

            case CalendarEventFrequencyType.WEEKLY:
              start.add(1, 'week')
              break

            case CalendarEventFrequencyType.MONTHLY:
              start.add(1, 'month')
              break

            default:
              start.add(1, 'day')
              break
          }
        } while (start.isBefore(end))
      } else {
        const end = moment(
          moment(event.startDate).format('YYYY-MM-DD') + ' ' + event.endTime
        )

        const newEvent: any = {
          id: event.id,
          allDay: event.allDay,
          start: start.toDate(),
          end: end.toDate(),
          title: event.title,
          description: event.description,
          data: event
        }
        mappedEvents.push(newEvent)
      }
    }

    return mappedEvents
  }
}
