export enum FeatureFlagEnum {
  INTERCOM = 'Intercom',
  PARENT_PORTAL = 'Parent-Portal',
  PREMIUM_PLAN = 'Premium-Plan',
  PROGRAM_TAGLINE = 'Program-Tagline',
  PROVIDER_MULTI_USER = 'Provider-Multi-User',
  PROVIDER_WIP = 'Provider-WIP',
}

export type IFeatureFlag = {
  [key in keyof typeof FeatureFlagEnum]: boolean;
}

