import {
  IPostmarkHtmlTemplate,
  ISendEmailInput,
  ISendCommonEmailInput,
  IUpdatePostmarkHtmlTemplateInput
} from 'src/types/email-template'
import { ICheckSlugAvailabilityInput, ICheckSlugAvailabilityResponse, IProvider, ISlugRecommendationsResponse } from 'src/types/provider'
import { instanceApi } from 'src/utils/axios'
import { CRUDService } from './core/crudService'

export class ProviderService extends CRUDService<IProvider> {
  protected apiPath = 'api/app/provider'

  protected fillable = [
    'name',
    'email',
    'description',
    'code',
    'ownerEmail',
    'websiteUrl',
    'customDomain',
    'customDomainVerified',
    'customDomainEnabled',
    'slug',
    'yelpUrl',
    'facebookUrl',
    'instagramUrl',
    'twitterUrl',
    'contact',
    'program',
    'guidance',
    'transportation',
    'schoolsServed',
    'programMeals',
    'buildingTypeSetting',
    'organizationType',
    'phone',
    'phoneOwner',
    'fax',
    'faxOwner',
    'capacity',
    'capacityVacancies',
    'capacityDate',
    'capacityInfant',
    'capacityInfantVacancies',
    'capacityToddler',
    'capacityToddlerVacancies',
    'capacityPreschool',
    'capacityPreschoolVacancies',
    'capacitySchool',
    'capacitySchoolVacancies',
    'capacityOnes',
    'rating',
    'ratingStar',
    'ratingSUTQ',
    'isFaithBased',
    'isReligiousExempt',
    'isReligiousActivity',
    'isHeadStart',
    'isOfferingSchoolReadiness',
    'isEnrolledInSubsidized',
    'isAcceptsChildCareSubsides',
    'isConditionsOnPermit',
    'isSpecialServices',
    'isSiblingDiscount',
    'originationDate',
    'inspectionDate',
    'license',
    'officialName',
    'addressLocationId',
    'addressLocation',
    'addressMailingId',
    'addressMailing',
    'addressOwnerId',
    'addressOwner',
    'licenseStatus',
    'licenseType',
    'licenseEffectiveDate',
    'licenseExpirationDate',
    'licenseAgeRange',
    'licensedCapacity',
    'licenseFirstShiftCapacity',
    'licenseSecondShiftCapacity',
    'licenseThirdShiftCapacity',
    'licensingReportUrl',
    'providerType',
    'providerStatus',
    'goldSealAccreditingAgency',
    'goldSealEffectiveDate',
    'goldSealExpirationDate',
    'openingHours',
    'openingHoursJson',
    'daysOfOperation',
    'mondayHours',
    'tuesdayHours',
    'wednesdayHours',
    'thursdayHours',
    'fridayHours',
    'saturdayHours',
    'sundayHours',
    'enrollmentInstructions',
    'enrollmentStatus',
    'logo',
    'avatar',
    'mainPhoto',
    'sidePhoto1',
    'sidePhoto2',
    'ownerName',
    'languages',
    'schedules',
    'agesServed',
    'summerProgram',
    'childPottyTrained',
    'annualCalendar',
    'educationApproach',
    'verificationStatus',
    'addressLocationCity',
    'addressLocationState',
    'addressLocationFullAddress',
    'addressLocationZipCode',
    'skipTours'
  ]

  create(request: Partial<IProvider> = {}) {
    request = this.requestMap(request)
    return super.create(request)
  }

  update(id: string | number, request: Partial<IProvider> = {}) {
    this.fillable.push('id')
    request = this.requestMap(request)
    return super.update(id, request)
  }

  getPostmarkHtmlTemplates(request: any = {}) {
    return instanceApi.get<{ templates: IPostmarkHtmlTemplate; count: number }>(
      `${this.apiPath}/postmark-html-templates`,
      { params: request }
    )
  }

  getPostmarkHtmlTemplate(request: any = {}) {
    return instanceApi.get<string>(`${this.apiPath}/postmark-html-template`, {
      params: request
    })
  }

  updatePostmarkHtmlTemplate(request: IUpdatePostmarkHtmlTemplateInput = {}) {
    return instanceApi.put<IPostmarkHtmlTemplate>(
      `${this.apiPath}/postmark-html-template`,
      request
    )
  }

  sendTwilioMessage(request: IUpdatePostmarkHtmlTemplateInput = {}) {
    return instanceApi.post(`${this.apiPath}/send-twilio-message`, request)
  }

  sendEmail(request: ISendEmailInput = {}) {
    return instanceApi.post(`${this.apiPath}/send-email`, request)
  }

  sendCommonEmail(request: ISendCommonEmailInput = {}) {
    return instanceApi.post(`${this.apiPath}/send-common-email-template`, request)
  }

  twilioMessagingServer(request: IUpdatePostmarkHtmlTemplateInput = {}) {
    return instanceApi.post(`${this.apiPath}/twilio-messaging-server`, request)
  }

  hideTrialRemainingDate() {
    return instanceApi.get(`${this.apiPath}/hide-trial-remaining-date`)
  }

  hideTrialRemaining() {
    return instanceApi.post(`${this.apiPath}/hide-trial-remaining`, {})
  }

  imageFeatureLibrary(request) {
    return instanceApi.get(`${this.apiPath}/image-feature-library`, {
      params: request
    })
  }

  checkSlugAvailability(request: ICheckSlugAvailabilityInput) {
    return instanceApi.get<ICheckSlugAvailabilityResponse>(`${this.apiPath}/check-slug-availability`, {
      params: request
    })
  }

  getSlugRecommendations() {
    return instanceApi.get<ISlugRecommendationsResponse>(`${this.apiPath}/slug-recommendations`)
  }

  mapItem(row: IProvider) {
    if (typeof row.languages === 'string') {
      row.languages = row.languages.length > 0 ? row.languages.split(',') : []
    } else {
      row.languages = row.languages || []
    }

    if (typeof row.schedules === 'string') {
      row.schedules = row.schedules.length > 0 ? row.schedules.split(',') : []
    } else {
      row.schedules = row.schedules || []
    }

    if (typeof row.agesServed === 'string') {
      row.agesServed = row.schedules.length > 0 ? row.agesServed.split(',') : []
    } else {
      row.agesServed = row.agesServed || []
    }

    if (typeof row.openingHours === 'string') {
      row.openingHours = row.openingHours.split(',')
    } else {
      row.openingHours = row.openingHours || []
    }

    if (typeof row.educationApproach === 'string') {
      row.educationApproach =
        row.educationApproach.length > 0 ? row.educationApproach.split(',') : []
    } else {
      row.educationApproach = row.educationApproach || []
    }

    if (row.enrollmentStatus) {
      if (row.enrollmentStatus === 'true') {
        row.enrollmentStatus = true
      }
      if (row.enrollmentStatus === 'false') {
        row.enrollmentStatus = false
      }
    }

    const jsonKeys = [
      'mondayHours',
      'tuesdayHours',
      'wednesdayHours',
      'thursdayHours',
      'fridayHours',
      'saturdayHours',
      'sundayHours',
      'daysOfOperation'
    ]

    for (const key of jsonKeys) {
      if (typeof row[key] === 'string') {
        try {
          row[key] = JSON.parse(row[key])
        } catch (error) {
          row[key] = null
        }
      }
    }

    return row
  }

  private requestMap(request) {
    request = Object.assign({}, request)

    if (request.languages instanceof Array) {
      request.languages = request.languages.join(',')
    }
    if (request.schedules instanceof Array) {
      request.schedules = request.schedules.join(',')
    }
    if (request.enrollmentStatus) {
      request.enrollmentStatus = 'true'
    } else {
      request.enrollmentStatus = 'false'
    }
    if (request.educationApproach instanceof Array) {
      request.educationApproach = request.educationApproach.join(',')
    }
    if (request.agesServed instanceof Array) {
      request.agesServed = request.agesServed.join(',')
    }

    if (request.openingHours instanceof Array) {
      const jsonDays = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday'
      ]
      const openingHoursJson = []
      jsonDays.map((item) => {
        openingHoursJson.push({
          day: item,
          open: request.openingHours[0],
          close: request.openingHours[1]
        })
      })
      request.openingHours = request.openingHours.join(',')
      request.openingHoursJson = JSON.stringify(openingHoursJson)
    }

    const jsonKeys = [
      'mondayHours',
      'tuesdayHours',
      'wednesdayHours',
      'thursdayHours',
      'fridayHours',
      'saturdayHours',
      'sundayHours',
      'daysOfOperation'
    ]

    for (const key of jsonKeys) {
      if (!request[key] || request[key] === '' || request[key] === 'null') {
        request[key] = null
      } else if (typeof request[key] === 'object') {
        request[key] = JSON.stringify(request[key])
      }
    }

    return request
  }
}
