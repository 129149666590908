import { IAddress } from './address'
import { IChild } from './child'
import { IBaseEntity } from './core'
import { IProvider } from './provider'

export interface IParent extends IBaseEntity {
  userId?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  facebook?: string
  facebookUrl?: string
  twitterUrl?: string
  linkedinUrl?: string
  instagramUrl?: string
  familyId?: string
  familyName?: string
  addressId?: string
  isPrimaryContact?: boolean
  lastUpdatePassword?: string
  registrationStatus?: RegistrationStatusEnum
  imageUrl?: string
  avatar?: string
  address?: IAddress
  fullAddress?: string
  children?: IChild[]
  dealStage?: string
  firstContact?: string
  tourDate?: string
  tourEndTime?: string
  tourStartTime?: string
}

export interface IParentProvider extends IBaseEntity {
  providerId?: string
  parentId?: string
  provider?: IProvider
  relationType?: string
  status?: string
}

export interface IRelationUpdateInput {
  providerId?: string
  parentId?: string
  relationType?: string
  status?: string
}

export enum RegistrationStatusEnum {
  COMPLETED = 0,
  PROCESSING = 1,
  INVITE_READ = 2,
  NOT_PROCESSING = 3,
  ENROLLED = 4
}
