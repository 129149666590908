import { IBaseEntity } from './core'
import { IParent } from './parent'

export interface IConversationMessage extends IBaseEntity {
  providerId: string
  parentId: string
  message?: string
  author?: ConversationAuthorEnum
  type?: ConversationTypeEnum
  subject?: string
  threadId?: string
  parent?: IParent
  readAt?: string
  lastMessage?: IConversationMessage
  status?: ConversationStatusEnum
  sendMessage?: boolean
  creationTimeUTC?: string
}

export interface IConversationThread extends IBaseEntity {
  providerId?: string
  parentId?: string
  isOpened?: boolean
  unreadMessagesCount?: number
  type?: ConversationTypeEnum
  lastMessage?: IConversationMessage
  messages?: IConversationMessage[]
}

export interface IConversation extends IParent {
  id?: string
  parentId?: string
  providerId?: string
  unreadMessagesCount?: number
  messagesCount?: number
  parent?: IParent
  readAt?: string
  isClosed?: boolean
  type?: ConversationTypeEnum
  lastMessage?: IConversationMessage
  showDate?: boolean
  threads?: IConversationThread[]
}

export enum ConversationAuthorEnum {
  PROVIDER = 0,
  PARENT = 1
}

export enum ConversationTypeEnum {
  EMAIL = 0,
  MICROSITE_CHAT = 1,
  TWILIO_CONVERSATION = 2,
  PORTAL_CHAT = 3
}

export enum ConversationMemberTypeEnum {
  ALL = 0,
  LEADS = 1,
  ACTIVE = 2,
  ALUMNA = 3
}

export enum ConversationStatusEnum {
  PENDING = 'pending',
  SENT = 'sent'
}

export interface IConversationMessageByParentInput {
  providerId?: string
  parentId?: string
  author?: number
  message?: string
  type?: number
  subject?: string
}

export interface IGetConversationInput {
  ParentId: string
  Year?: string
  Month?: string
}

export interface ITwilioTenantSetting {
  id?: string
  tenantId?: string
  sid?: string
  authToken?: string
}

export interface ISendTwilioMessageInput {
  parentNumber?: string
  text?: string
}
