import { IBaseEntity } from "./core";
import { IDeal } from "./deal";

export interface IPipeline extends IBaseEntity {
  pipelineType?: string;
  pipelineStages?: IPipelineStage[];
}

export interface IPipelineStage extends IBaseEntity {
  name?: string;
  pipelineId?: string;
  stageOrder?: number;
  deals?: IDeal[];
}


export interface PipelineWithDetailsInput {
  PipelineType?: string;
  PipelineRelationType?: PipelineRelationTypeEnum;
}

export enum PipelineRelationTypeEnum {
  PIPELINE_STAGES = 0,
  DEALS = 1,
  DEALS_PARENTS = 2,
  DEALS_CHILDS = 3,
  DEALS_PARENTS_AND_DEALS_CHILDS = 4,
  PARENT = 5,
  CHILD = 6,
  PARENT_AND_CHILD = 7,
}