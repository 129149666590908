import { find } from 'lodash';
import { IPagination } from 'src/types';
import { IFamily, IGetByFilterInput } from 'src/types/families';
import { instanceApi } from 'src/utils/axios';
import { ChildService } from './childService';
import { CRUDService } from './core/crudService';
import { ParentService } from './parentService';

const childService = ChildService.getInstance<ChildService>();
const parentService = ParentService.getInstance<ParentService>();
export class FamilyService extends CRUDService<IFamily> {

    protected apiPath = '/api/app/family';

    protected fillable = ['name', 'providerId'];

    getByFilters(request: IGetByFilterInput) {
        return instanceApi
            .post<IPagination<IFamily>>(`${this.apiPath}/by-filters`, request)
            .then((resp) => {
                resp.data.items = resp.data.items.map(this.mapItem)  
                return resp;
            });
    }

    mapItem(item: IFamily) {
        if (item.parents) {
            item.parents = item.parents.map((child) => parentService.mapItem(child));
            if (!item.primaryParent) {
                item.primaryParent = find(item.parents, { isPrimaryContact: true })
            }
        }

        if (item.children) {
            item.children = item.children.map((child) => childService.mapItem(child))
        }
        return item;
    }
}
