import { IBaseEntity } from "./core";

export interface IEmailTemplate extends IBaseEntity {
    title?: string;
    description?: string;
    content?: string;
    isActive?: string;
    providerId?: string;
}


export interface IPostmarkHtmlTemplate {
    isActive?: boolean;
    alias?: PostmarkHtmlTemplateEnum;
    description: string;
    layoutTemplate?: string;
    name?: string;
    templateId: number;
}


export enum PostmarkHtmlTemplateEnum {
    TOUR_SCHEDULED = 'schedule_tour_confirmation',
    WELCOME = 'invite-parent-registration',
}


export interface IUpdatePostmarkHtmlTemplateInput {
    alias?: string;
    htmlBody?: string;
}

export interface ISendEmailInput {
    emailsTo?: string[];
    body?: string;
    subject?: string;
    threadId?: string;
}

export interface ISendCommonEmailInput {
    parentIds?:  string[];
    greeting?: string;
    dealId?: string;
}

export interface ITemplateVariable {
    name?: string;
    group?: string;
    value?: string;
}

export interface IProviderTemplate extends IBaseEntity {
    providerId?: string,
    name?: string;
    htmlContent?: string;
}

export interface ITemplate {
    id?: string;
    creationTime?: string;
    creatorId?: string;
    lastModificationTime?: string;
    providerId?:string;
    name?: string;
    htmlContent?: string;
}
