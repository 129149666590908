import { ISendTwilioMessageInput, ITwilioTenantSetting } from 'src/types/conversation'
import { instanceApi } from 'src/utils/axios'
import { Service } from './core/service'

export class TwilioMessageService extends Service {
  protected apiPath = 'api/app/provider'

  twilioTenantSettings() {
    return instanceApi.post<ITwilioTenantSetting>(`api/app/twilio-tenant-settings/account`, {})
  }

  sendTwilioMessage(threadId, request: ISendTwilioMessageInput = {}) {
    return instanceApi.post(`${this.apiPath}/send-twilio-message/${threadId}`, {}, { params: request })
  }

  twilioMessagingServer() {
    return instanceApi.post(`${this.apiPath}/twilio-messaging-server`, {})
  }

}
