

import { IPagination } from 'src/types';
import { IGetTimelineByFamilyInput, ITimeline } from 'src/types/timeline'
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService'

export class TimelineService extends CRUDService<ITimeline> {

  protected apiPath = '/api/app/timeline'

  protected fillable = [
    "tenantId",
    "providerId",
    "familyId",
    "event",
    "type",
    "person",
    "description",
  ]

  byFamily(request: IGetTimelineByFamilyInput) {
    return instanceApi.get<IPagination<ITimeline>>(`${this.apiPath}/by-family`, {
      params: request
    }).then((resp)=>{
      resp.data.items = resp.data.items.map(this.mapItem);
      return resp
    });
  }

  mapItem(item){
    try {
      item.description = JSON.parse(item.description);
    } catch (error) {
      
    }
    return item;
  }

}
