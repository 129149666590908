/* eslint-disable no-unused-vars */
import { IBaseEntity } from './core'
import { IAddress } from './address'
import { IMediaFile, ITour } from './tours'

export interface IProvider extends IBaseEntity {
  name: string
  slug: string
  email: string
  description: string
  code: string
  ownerName: string
  ownerEmail: string
  websiteUrl: string
  yelpUrl: string
  facebookUrl: string
  instagramUrl: string
  contact: string
  program: string
  guidance: string
  transportation: string
  schoolsServed: string
  programMeals: string
  buildingTypeSetting: BuildingTypeSettingEnum
  organizationType: string
  phone: string
  phoneOwner: string
  fax: string
  faxOwner: string
  capacity: number
  capacityVacancies: number
  capacityDate: Date
  capacityInfant: number
  capacityInfantVacancies: number
  capacityToddler: number
  capacityToddlerVacancies: number
  capacityPreschool: number
  capacityPreschoolVacancies: number
  capacitySchool: number
  capacitySchoolVacancies: number
  capacityOnes: number
  rating: number
  ratingStar: number
  ratingSUTQ: number
  isFaithBased: boolean
  isReligiousExempt: boolean
  isReligiousActivity: boolean
  isHeadStart: boolean
  isOfferingSchoolReadiness: boolean
  isEnrolledInSubsidized: boolean
  isAcceptsChildCareSubsides: boolean
  isConditionsOnPermit: boolean
  isSpecialServices: boolean
  isSiblingDiscount: boolean
  originationDate: Date
  inspectionDate: Date
  license: string
  officialName: string
  addressLocationId: string
  addressLocation: IAddress
  addressMailingId: string
  addressMailing: IAddress
  addressOwnerId: string
  addressOwner: IAddress
  licenseStatus: LicenseStatusEnum
  licenseType: LicenseTypeEnum
  licenseEffectiveDate: Date
  licenseExpirationDate: Date
  licenseAgeRange: string
  licensedCapacity: number
  licenseFirstShiftCapacity: number
  licenseSecondShiftCapacity: number
  licenseThirdShiftCapacity: number
  licensingReportUrl: string
  providerType: ProviderTypeEnum
  providerStatus: 0 // TO-DO : Define enum for this type
  goldSealAccreditingAgency: string
  goldSealEffectiveDate: Date
  goldSealExpirationDate: Date
  openingHours: string | string[]
  openingHoursJson: string | any[]
  daysOfOperation: string | string[]
  mondayHours: string
  tuesdayHours: string
  wednesdayHours: string
  thursdayHours: string
  fridayHours: string
  saturdayHours: string
  sundayHours: string
  agesServed: string | string[]
  languages: string | string[]
  schedules: string | string[]
  enrollmentInstructions: string
  enrollmentStatus: string | boolean
  logo: string
  avatar: string
  verificationStatus: ProviderVerificationStatusEnum
  tours: ITour[]
  licensePhotos: ILicensePhotos[]
  childPottyTrained: boolean
  summerProgram: boolean
  annualCalendar: AnnualCalendarEnum
  educationApproach: string | string[]
  stripeConnectAccountId: string
  mainPhoto: string
  sidePhoto1: string
  sidePhoto2: string
  skipTours?: boolean
  providerRegistrySubscriptions?: IProviderRegistrySubscriptions[]
  aboutUs?: IAboutUs
}

export enum ProviderThemesEnum {
  THEME1 = 'Theme1',
  THEME2 = 'Theme2'
}

export enum ProviderStylesEnum {
  STYLE1 = 'Style1',
  STYLE2 = 'Style2',
  STYLE3 = 'Style3',
  STYLE4 = 'Style4',
  STYLE5 = 'Style5'
}

export enum ProviderSchedulesEnum {
  HALF_DAY = 'Half Day',
  EARLY_CARE = 'Early Care',
  DROP_IN = 'Drop In',
  SICK_CARE = 'Sick Care',
  FULL_DAY = 'Full Day',
  AFTER_CARE = 'After Care',
  EVENINGS = 'Evenings',
  WEEKENDS = 'Weekends'
}

export enum ProviderProgramsEnum {
  HALF_DAY = 'Half Day',
  FULL_DAY = 'Full Day',
  EXTENDED_DAY = 'Extended Day',
  SUMMER = 'Summer'
}

export enum DefaultProgramsEnum {
  INFANT = 'Infant',
  TODDLER = 'Toddler',
  PRESCHOOL = 'Preschool',
  SUMMER = 'Summer'
}

export enum DefaultTemplateItemsEnum {
  INFANTS = 'Infants',
  ONE_YEAR_OLDS = 'One Year Olds',
  TWO_YEAR_OLDS = 'Two Year Olds',
  THREE_YEAR_OLDS = 'Three Year Olds',
  FOUR_YEAR_OLDS = 'Four Year Olds'
}

export enum ProviderCaresEnum {
  EARLY_CARE = 'Early Care',
  AFTER_CARE = 'After Care',
  DROP_IN = 'Drop In',
  EVENINGS = 'Evenings',
  WEEKENDS = 'Weekends',
  SICK_CARE = 'Sick Care'
}

export enum EducationsEnum {
  REGGIO_EMELIA = 'Reggio Emelia',
  MONTESSORI = 'Montessori',
  WALDORF = 'Waldorf',
  BANK_STREET = 'Bank Street',
  FOREST_SCHOOL = 'Forest School',
  CREATIVE_CURRICULUM = 'Creative curriculum',
  TOOLS_OF_THE_MIND = 'Tools of the mind',
  HIGH_SCOPE = 'High scope'
}

export enum AnnualCalendarEnum {
  FULL_YEAR = 1,
  SCHOOL_YEAR = 2,
  SCHOOL_YEAR_AND_SUMMER = 3
}

export enum BuildingTypeSettingEnum {
  UNKNOWN = 0,
  IN_PUBLIC_SCHOOL = 1,
  HOUSE = 2,
  IN_FREE_STANDING_BUILDING = 3,
  IN_CHURCH = 4,
  IN_PRIVATE_SCHOOL = 5,
  IN_WORKPLACE = 6,
  MOBILE_HOME = 7,
  TOWNHOUSE = 8,
  DUPLEX = 9,
  APARTMENT = 10,
  APARTMENT_CONDO = 11,
  NON_RESIDENTIAL = 12
}

export enum LicenseStatusEnum {
  UNKNOWN = 0,
  UNLICENSED = 1,
  OPERATIONAL = 2,
  INACTIVE = 3,
  ON_PROBATION = 4,
  SUBSTANTIAL_COMPLIANCE = 5,
  EXEMPT = 6,
  LICENSED = 7,
  REGISTERED = 8,
  CLOSED = 9,
  CLOSED_TEMPORARILY = 10,
  OPEN = 11,
  PENDING = 12,
  OPEN_CERTIFIED = 13,
  OPEN_PAYMENT_ONLY = 14,
  CLOSED_PAYMENT_ONLY = 15
}

export enum LicenseTypeEnum {
  UNKNOWN = 0,
  FULL = 1,
  REGISTERED = 2,
  CERTIFIED = 3,
  COMPLIANCE_CERTIFICATE = 4,
  INITIAL = 5,
  RENEWAL = 6,
  RENEWED_INITIAL = 7,
  PROBATIONARY = 8,
  FULL_AMENDED = 9,
  FULL_MODIFIED = 10,
  ONE_STAR_FAMILY_CC_HOME = 11,
  TWO_STAR_FAMILY_CC_HOME = 12,
  FOUR_STAR_FAMILY_CC_HOME = 13,
  THREE_STAR_FAMILY_CC_HOME = 14,
  FIVE_STAR_FAMILY_CC_HOME = 15,
  ONE_STAR_CENTER = 16,
  TWO_STAR_CENTER = 17,
  THREE_STAR_CENTER = 18,
  FOUR_STAR_CENTER = 19,
  FIVE_STAR_CENTER = 20,
  GS110106 = 21,
  PROV = 22,
  SP_PROV = 23,
  PROV_GS110106 = 24,
  SUMMER_DAY_CAMP_GS110106 = 25,
  PROV_FCCH = 26,
  TEMPORARY_FCCH = 27,
  TEMPORARY = 28
}

export enum ProviderTypeEnum {
  UNKNOWN = 0,
  CENTER = 1,
  LICENSED_CENTER = 2,
  LICENSED_HOME = 3,
  REGISTERED_HOME = 4,
  PUBLIC_SCHOOL = 5,
  GROUP_HOME = 6,
  IN_HOME_AIDE = 7,
  FAMILY_HOME = 8,
  REGISTERED_FAMILY_HOME = 9,
  LICENSED_FAMILY_HOME = 10,
  TYPE_A_FAMILY_HOME = 11,
  TYPE_B_FAMILY_HOME = 12,
  SCHOOL_AGE_PROGRAM = 13,
  PRESCHOOL = 14,
  OUTDOOR_PRESCHOOL_PROGRAM = 15,
  AFTERSCHOOL_PROGRAM = 16,
  REGISTERED_DAY_CAMP = 17,
  SUMMER_DAY_CAMP = 18,
  SMALL_EMPLOYER_BASED = 19,
  CENTER_BASED_CHILD_CAREAND_PRESCHOOL_PROGRAM = 20,
  CENTER_BASED_CHILD_CAREAND_PRESCHOOL_PROGRAM_NON_RECURRING = 21
}

export enum ProviderVerificationStatusEnum {
  UNPROCESSED = 0,
  REQUESTED = 1,
  VERIFIED = 2,
  CLAIMED = 3
}

export interface ILicensePhotos extends IBaseEntity {
  title: string
  path: string
  providerId: string
}

export interface IUserProvider extends IBaseEntity {
  ownershipType?: OwnershipTypeEnum
  userId?: string
  providerId?: string
  ownershipTypes?: OwnershipTypeEnum[]
  mostImportantTypes?: MostImportantTypeEnum[]
  organizationSize?: OrganizationSizeEnum[]
}

export interface IUserProviderUser extends IBaseEntity {
  providerRole?: ProviderRoleEnum
  userType?: UserTypeEnum
  micrositePhoto?: string
  email?: string
  name?: string
  surname?: string
  phoneNumber?: string
  imageUrl?: string
  isDeleted?: boolean
  status?: ProviderUserStatusEnum
  role: ProviderUserRoleEnum
  portalAccess?: boolean
  parentCommIdentity?: boolean
  parentCommRole?: string
  archivedLead?: boolean
  futureLead?: boolean
  registerLead?: boolean
}

export enum ProviderUserRoleEnum {
  PROVIDER_STAFF_ADMIN = 'provider_staff_admin',
  PROVIDER_STAFF = 'provider_staff',
  WEBSITE_CONTENT = 'website_content',
  PARENT_ENGAGEMENT = 'parent_engagement'
}

export enum ProviderRoleEnum {
  TEACHER = 0,
  ASSISTANT = 1,
  DIRECTOR = 2,
  ADMINISTRATOR = 3
}

export enum ProviderUserStatusEnum {
  INVITED = 0,
  ACCEPTED = 1,
  REJECTED = 2
}

export enum UserTypeEnum {
  Admin = 0,
  Staff = 1
}

export enum OwnershipTypeEnum {
  OWNER = 0,
  DIRECTOR = 1,
  BOTH = 2
}

export enum MostImportantTypeEnum {
  GET_ORGANIZED = 0,
  SAVE_TIME = 1,
  GROW = 2
}
export enum OrganizationSizeEnum {
  ONE_CENTER = 0,
  FROM_TWO_TO_TEN_CENTERS = 1,
  MORE_THAN_TEN_CENTERS = 2
}

export interface IProviderMediaFile extends IBaseEntity {
  providerId?: string
  mediaFileId?: string
  fileOrder?: number
  mediaFileDto?: IMediaFile
}

export interface IProviderRegistrySubscriptions extends IBaseEntity {
  providerId?: string
  edition?: string
}

export interface ICheckSlugAvailabilityInput {
  slug: boolean
}

export interface ICheckSlugAvailabilityResponse {
  isAvailable?: boolean
}

export interface ISlugRecommendationsResponse {
  slugRecommendations?: string[]
}

export interface IAboutUs extends IBaseEntity {
  tenantId?: string
  providerId?: string
  name?: string
  description?: string
  goalImageUrl?: string
  providerStaffs?: IProviderStaff[]
  providerApproachElements?: IProviderApproachElements[]
}

export interface IProviderStaff extends IBaseEntity {
  tenantId?: string
  providerId?: string
  avatar?: string
  name?: string
  description?: string
  welcomeLetter?: string
  type?: number
  providerMicrositeAboutUsId?: string
  questions?: IProviderStaffQuestions[]
}

export interface IProviderStaffQuestions extends IBaseEntity {
  tenantId?: string
  providerId?: string
  category?: string
  question?: string
  questionImageUrl?: string
  questionImageAlt?: string
  type?: number
  order?: number
  answers?: IProviderStaffAnswers[]
}

export interface IProviderStaffAnswers extends IBaseEntity {
  tenantId?: string
  providerId?: string
  masterQuestionId?: string
  providerStaffId?: string
  order?: number
  answer?: string
}

export interface IProviderApproachElements extends IBaseEntity {
  tenantId?: string
  providerId?: string
  name?: string
  imageUrl?: string
  topic?: number
  providerMicrositeAboutUsId?: string
  backgroundColor?: string
  order?: number
  content?: IProviderApproachElementContent[]
}

export interface IProviderApproachElementContent extends IBaseEntity {
  tenantId?: string
  providerId?: string
  description?: string
  link?: string
  providerApproachElementId?: string
  order?: number
}
