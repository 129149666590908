import { IBaseEntity, IPaginationRequest } from './core'

export interface ITimeline extends IBaseEntity {
  tenantId?: string
  providerId?: string
  familyId?: string
  event?: TimelineEventEnum
  type?: TimelineTypeEnum
  person?: string
  description?: any
  creationTimeUTC?: Date
  triggeringActor?: number
  feature?: string
}

export interface IGetTimelineByFamilyInput extends IPaginationRequest {
  FamilyId: string
}

export interface ITimelineItemGroup {
  date: string
  timelineItems: ITimeline[]
}

export enum TimelineEventEnum {
  FAMILY_UPDATED = 'Family updated',
  ADDED_CHILD = 'Added child',
  UPDATED_CHILD = 'Updated child',
  ADDED_PARENT = 'Added parent',
  UPDATED_PARENT = 'Updated parent',
  DEAL_CREATED = 'Deal created',
  DEAL_STATUS_CHANGE = 'Deal status change',
  EMAIL_SENT = 'Email sent',
  ADDED_FAMILY_AND_PRIMARY_PARENT = 'Added Family/Primary Parent'
}

export enum TimelineTypeEnum {
  MANUAL = 'Manual'
}
