import { IAudioFile, IStoryAudioFile } from 'src/types/tours';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class StoryAudioFileService extends CRUDService<IStoryAudioFile> {
  protected apiPath = 'api/app/story-audio-file';


  async syncFiles(newFiles: IAudioFile[], input: any = {}) {
    const request = {
      ...input,
      files: newFiles.map((file, index) => ({ audioFileId: file.id, fileOrder: index }))
    }
    return instanceApi.post<IStoryAudioFile[]>(`${this.apiPath}/multiple-files`, request)
  }
}
