import { IStory, ITourStory } from 'src/types/tours';
import { instanceApi } from 'src/utils/axios';
import { CRUDService } from './core/crudService';

export class TourStoryService extends CRUDService<ITourStory> {

  protected apiPath = '/api/app/tour-story';

  async sync(newFiles: IStory[], input: any = {}) {
    const request = {
      ...input,
      stories: newFiles.map((file, index) => ({ storyId: file.id, fileOrder: index }))
    }
    return instanceApi.post<ITourStory[]>(`${this.apiPath}/multiple-files`, request)
  }
}